import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import AddIcon from "@material-ui/icons/Add";
import TaxManagementClientTable from "../TaxManagementClientTable";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import FilterViewClient from "../FilterViewClient";
import Drawer from "@material-ui/core/Drawer";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link, Redirect} from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import PhoneInput from 'react-phone-input-2';
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(ClientDetails, comparator) {
  const stabilizedThis = ClientDetails && ClientDetails.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
} 

const headCells = [
  { id: 'client_pan', numeric: false, label: 'PAN #' , },
  { id: 'client_type', numeric: false, label: 'Client Type' , },
  { id: 'first_name', numeric: false, label: 'Name / Org', },
  { id: 'phone', numeric: false, label: 'Phone Number' , },
  { id: 'email', numeric: false, label: 'Email ID' ,},
  { id: 'client_state', numeric: false, label: 'Client Status' , },
  { id: 'actions', label: 'Actions' , },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          
          
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  // root: {
  //   // color: "#eaeaf0",
  //   // display: "flex",
  //   // height: 22,
  //   alignItems: "center",
  // },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  showProcedureStyle: {
    // display: "flex",
    // alignItems: "center",
    // alignSelf: "center",
    // marginLeft: "50%",
    // marginTop: "-20%",
     position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  additionalDetail: {
    background: "#E5E5E5",
  },
  buttonContainer: {
    textAlign: "right",
    margin: 1,
    marginLeft:'2%'
  },
  casesdisplay: {
    display: "block",
  },
  casesdisplayNone: {
    display: "none",
  },
  customFilterLabel: {
    textTransform: "none",
    background: "#FFFFFF",
  },
  drawerPaper: {
    marginTop: "145px",
    width: "425px",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  marginTopStyle: {
    marginTop:"250px"
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  table: {
    minWidth: 500, 
  },
  tableWrapper:{
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  // hover: {
  //   cursor: "pointer",
  // },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  SearchBoxStyle:{
    background: "#FFFFFF",
    width: "150%",
    marginLeft: "-50%",
    // height: "10%",
  },
  buttonStyle:{
    // margin:1,
  },
  hover: {
    cursor: "pointer",
  },
  containedPurple: {
    margin: 1,
    color: 'white',
    backgroundColor: '#294b94',
    "&:hover": {
      backgroundColor: '#294b94',
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: '#294b94'
      }
    }
  },
  button: {
    margin: "1%",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'15%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  formControl: {
    marginLeft: "7%",
    marginTop: '2%',
    width: '80%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
    marginTop: '0.5%',
    marginLeft : '7%',
  },
  
}));

export default function TaxManagementClient(props) {
  const classes = useStyles();
  const [ device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [ session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [ business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [checked, setChecked] = React.useState(false);
  const [clientList, setClientList] = React.useState();
  const [ClientDetails, setClientDetails] = React.useState([]);
  const [RedirectToGetClientDetailsPage, setRedirectToGetClientDetailsPage] = React.useState(false);
  const [RedirectToEditClientDetails, setRedirectToEditClientDetails] = React.useState(false);
  const [OpenMobileCountryCode, setOpenMobileCountryCode] = React.useState(false);
  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
  ]);
  const [CountryListForCountryCode, setCountryListForCountryCode] = React.useState([
    {country:'India', country_code: '91'},
    {country:'United States', country_code: '1'},
    {country:'Australia', country_code: '61'},
    {country:'Bhutan', country_code: '975'},
    {country:'Canada', country_code: '1'},
    {country:'Germany', country_code: '49'},
    {country:'Japan', country_code: '81'},
    {country:'Nepal', country_code: '977'},
    {country:'Singapore', country_code: '65'},
    {country:'United Arab Emirates', country_code: '971'},
    {country:'United Kingdom', country_code: '44'},
  ])
  const [SlecetedClientDetailsToGetCountryCode, setSlecetedClientDetailsToGetCountryCode] = React.useState();
  const [CountyrCodefromModal, setCountyrCodefromModal] = React.useState();
  const [countryCodeErr, setcountryCodeErr] = React.useState();

  const [flag, setflag] = React.useState(false);
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  console.log("user_permissions from local storage on header page::::::",user_permissions)

  // const loading = clientList !== null;
  const [ trial, settrial ] = React.useState("");
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [paginationDetails, setpaginationDetails] = React.useState({total_clients:0});
  const [totalNumberOfPagesFromApi, settotalNumberOfPagesFromApi] = React.useState();
  const [recentPageNumberFromApi, setrecentPageNumberFromApi] = React.useState();
  const [ShowTableFlag, setShowTableFlag] = React.useState(true);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, ClientDetails && ClientDetails.total_clients - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      let nuberOfRowsPerPage = rowsPerPage

      getNextClientsAfterPagination(newPage, nuberOfRowsPerPage)
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);

      let Rows_per_page_here = parseInt(event.target.value, 10);
      let page_here = 0;
      getNextClientsAfterPagination(page_here, Rows_per_page_here)
    };


  const getNextClientsAfterPagination = (newPage, nuberOfRowsPerPage) => {
    console.log("new page number is", newPage, newPage + 1);
    console.log("nuberOfRowsPerPage in next", nuberOfRowsPerPage);
    setShowProcedure(true);
    setShowTableFlag(false);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,'device_token': device_token, 'session_id': session_id,
        'page': newPage + 1, 'per_page': nuberOfRowsPerPage
      })
    };
    fetch( process.env.REACT_APP_GET_CLIENTS , requestOptions)
      .then((Response) => Response.json())
      .then( clientDetails => {
        setShowProcedure(false);
        console.log("clientDetails after pagination",clientDetails);
        if(clientDetails.data.clients)
        {
          setClientDetails(clientDetails.data.clients)
          setShowTableFlag(true)
        }
        if(clientDetails.data.pagination)
        {
          setpaginationDetails(clientDetails.data.pagination)

          setRowsPerPage(nuberOfRowsPerPage)

          setrecentPageNumberFromApi(clientDetails.data.pagination.current_page)

          settotalNumberOfPagesFromApi(clientDetails.data.pagination.total_pages)
        }
        if(clientDetails.success == false){
          setClientDetails([])
          let total_clients_here = 0
          setpaginationDetails({...paginationDetails, total_clients:total_clients_here })
            Swal.fire({
            icon: 'error',
            text: clientDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  // React.useEffect(() => {
  //   let active = true;
  //   let usersData = JSON.parse(localStorage.getItem("username"));
  //   if (!loading) {
  //     return <CircularProgress color="inherit" size={20} />;
  //   }
  //   (async () => {
  //     const response = await fetch( process.env.REACT_APP_GET_CLIENTS , {
  //       method: "POST",
  //       headers: authHeader(),
  //       body: JSON.stringify({ username: usersData }),
  //     })
  //       .catch((error) => console.log(error));
  //     const clientListData = await response.json();

  //     if (active) {
  //       setClientList(clientListData.clients);
  //     }
  //   })();
  //   return () => {
  //     active = false;
  //   };
  // }, [loading]);

  useEffect(() => {
      // readItemFromStorage();
      dispatch(userActions.checkSessionValidity());
      localStorage.removeItem('view_clients_active_cases');
      // localStorage.setItem('pageNumberOfTableCasesTableTrpAdmin', 0);
      // localStorage.setItem('rowsPerPageOfTableCasesTableTrpAdmin', 5);
      setflag(true) 
      settrial("");
        // let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
        // console.log("business_id clientDetails on same page",business_id)
        // let device_token = JSON.parse(localStorage.getItem('device_token'));
        // let session_id = JSON.parse(localStorage.getItem('session_id'));
        let per_page_here = 100;

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'business_id': business_id,'device_token': device_token, 
                'session_id': session_id,
              'page': 1, 'per_page': per_page_here })
        };

        fetch( process.env.REACT_APP_GET_CLIENTS , requestOptions)
        .then((Response) => Response.json())
        .then( clientDetails => {
            console.log("clientDetails on same page",clientDetails, clientDetails.data.pagination);
            if(clientDetails.data.clients)
            {
                setClientDetails(clientDetails.data.clients)
            }
            if(clientDetails.data.pagination)
            {
              console.log("in pagination clientDetails.data.pagination", clientDetails.data.pagination)
              setpaginationDetails(clientDetails.data.pagination)

              setRowsPerPage(per_page_here)

              setrecentPageNumberFromApi(clientDetails.data.pagination.current_page)

              settotalNumberOfPagesFromApi(clientDetails.data.pagination.total_pages)
            }
            if(clientDetails.success == false){
              setClientDetails([])
              let total_clients_here = 0
              setpaginationDetails({...paginationDetails, total_clients:total_clients_here })
                Swal.fire({
                icon: 'error',
                text: clientDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }, [trial, flag]);
  
  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //       console.log("ERROR:",error);
  //   }
  // };

  const handleClickInvite = (item) => {

    console.log("item in handleClickInvited", item, item.phone.charAt(0))
    // if(item.phone.charAt(0) !== "+"){
    if(item.country_code === ""){
      console.log("Country code is missing")
      handleOpenMobileCountryCode(item);
      setSlecetedClientDetailsToGetCountryCode(item)
    }

    else{
      setShowProcedure(true);
      console.log("Invite is clicked",item.client_id);
      console.log("Invite clicked device_token,session_id, ",device_token,session_id);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'client_id': item.client_id , 'device_token': device_token, 
          'session_id': session_id })
      };
      fetch( process.env.REACT_APP_INVITE_CLIENT , requestOptions)
          .then((Response) => Response.json())
          .then( clientInvited => {
            console.log("clientInvited",clientInvited.data.client_info);
            setShowProcedure(false)
            if(clientInvited.success == true)
            {
              settrial("ok");
              Swal.fire({
                icon: 'success',
                text: "Client Invited",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
            if(clientInvited.success == false){
              Swal.fire({
                icon: 'error',
                text: clientInvited.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
          })
          .catch(err => {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
    }
  }

  const getFilteredListFunc = (e) => {
    // console.log("getFilteredListFunc",e.target.value);
    let search_string = e.target.value;
    console.log("search_string",search_string)
    console.log("client search device_token,session_id, ",device_token,session_id);

    // let formdata = new FormData();
    //   formdata.append('search_string', search_string);
    //   formdata.append('device_token', device_token);
    //   formdata.append('session_id', session_id);
    if( search_string !== "" ){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // body: formdata,
        body: JSON.stringify({'search_string': search_string, 'device_token': device_token, 
          'session_id': session_id, "client_type": "ALL" })
      };

      fetch( process.env.REACT_APP_SEARCH_CLIENTS , requestOptions)
        .then((Response) => Response.json())
        .then( clientDetails => {
          console.log("clientDetails in filter",clientDetails.data.clients);
          if(clientDetails.data.clients)
          {
            // settrial("ok");
            setClientDetails(clientDetails.data.clients)
            setPage(0)
            setRowsPerPage(5)
          }
          if(clientDetails.success == false){
            setClientDetails([])
            // let total_clients_here = 0
            // setpaginationDetails({...paginationDetails, total_clients:total_clients_here })
            Swal.fire({
              icon: 'error',
              text: clientDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleRowClickOfTable = async(item) => {
    
    await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(item));
    await localStorage.setItem('navigated_toshow_client_details_from', JSON.stringify("TaxManagemantClient"));

    if(item.client_state == "REGISTERED"){
      if(user_permissions && user_permissions.permissions.view_client_details == true){
        await setRedirectToGetClientDetailsPage(true);
      }
    }
    
    else if(item.client_state == "INVITED"){
      // { user_permissions && user_permissions.permissions.create_client &&
      if( user_permissions && user_permissions.permissions.create_client === true){
        await localStorage.setItem('selected_Client_To_Edit_Self_Details', JSON.stringify(item));
        await localStorage.setItem('navigated_to_edit_self_client_details', JSON.stringify("TaxManagemantClient"));
        await setRedirectToEditClientDetails(true);
      }
      // }
    }
    else if(item.client_state == "CREATED"){
      // { user_permissions && user_permissions.permissions.create_client &&
      if( user_permissions && user_permissions.permissions.create_client === true){
        await localStorage.setItem('selected_Client_To_Edit_Self_Details', JSON.stringify(item));
        await localStorage.setItem('navigated_to_edit_self_client_details', JSON.stringify("TaxManagemantClient"));
        await setRedirectToEditClientDetails(true);
      }
      // }
    }
    else{
      Swal.fire({
      // icon: 'error',
        text: "Client is not Registered yet",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      }) 
    }
  }

  const handleClickRegistered = async(item) => {
    await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(item));
    await localStorage.setItem('navigated_toshow_client_details_from', JSON.stringify("TaxManagemantClient"));
    await setRedirectToGetClientDetailsPage(true);
  }

  const handleClickDetailsOfUnregistered = async(item) => {
    await localStorage.setItem('selected_Client_Showin_Ui', JSON.stringify(item));
    await localStorage.setItem('navigated_toshow_client_details_from', JSON.stringify("TaxManagemantClient"));
    await setRedirectToGetClientDetailsPage(true);
  }

  const handleClickInvited = async(item) =>{
    console.log("item in handleClickInvited", item, item.phone.charAt(0))
    // if(item.phone.charAt(0) !== "+"){
    if(item.country_code === ""){
      console.log("Country code is missing")
      handleOpenMobileCountryCode(item);
      setSlecetedClientDetailsToGetCountryCode(item)
    }
    else{
      await localStorage.setItem('selected_Client_To_Edit_Self_Details', JSON.stringify(item));
      await localStorage.setItem('navigated_to_edit_self_client_details', JSON.stringify("TaxManagemantClient"));
      await setRedirectToEditClientDetails(true);
    }
  }

  const handleOpenMobileCountryCode = async(item) => {
    setOpenMobileCountryCode(true);
    console.log("Country code is missing",item)
  }

  const handleCloseMobileCountryCode = async() => {
    setOpenMobileCountryCode(false);
    setCountyrCodefromModal();
    setcountryCodeErr();
    setSlecetedClientDetailsToGetCountryCode();
  }

  const handleChangeCountyrCodefromModal = async(e) => {
    console.log("country code is", e.target.value)
    setCountyrCodefromModal(e.target.value)
  }

  const validate = () => {
    let countryCodeErr  = '';

    if(!CountyrCodefromModal)  {
      countryCodeErr = 'Please Select Country';
    }

    if(countryCodeErr ){
      setcountryCodeErr(countryCodeErr);

    return false;
  }
  return true;
  }

  const handleSubmitMobileCountryCode = () => {
    const isValid = validate();
    if(isValid){
      console.log("in handleSubmitMobileCountryCode",CountyrCodefromModal)

      console.log("SlecetedClientDetailsToGetCountryCode in handleSubmitMobileCountryCode",
        SlecetedClientDetailsToGetCountryCode, SlecetedClientDetailsToGetCountryCode.client_id)

      ClientDetails.map((item)=> {
        // console.log("item is",item, item.client_id, SlecetedClientDetailsToGetCountryCode.client_id)
        if (item.client_id === SlecetedClientDetailsToGetCountryCode.client_id){
          console.log("client id matched",item.phone,CountyrCodefromModal)
          let phoneNumberHere = '+'+CountyrCodefromModal+'-'+item.phone
          console.log("phoneNumberHere",phoneNumberHere)

          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
              'client_id': item.client_id,
              'name': item.name,
              'email': item.email,
              'phone': phoneNumberHere,
              'client_pan': item.client_pan,
              'self_assessment': item.self_assessment,
              'business_id': business_id,
              'device_token': device_token,
              'session_id': session_id,
            })
          };
          return fetch( process.env.REACT_APP_CREATE_CLIENT , requestOptions)
            .then((Response) => Response.json())
            .then( async(clientEdited) => {
              console.log("clientEdited",clientEdited.success);
              if(clientEdited.success == true){
                Swal.fire({
                  icon: 'success',
                  text: "Client's Phone Number Updated Succesfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK'
                })
                setOpenMobileCountryCode(false);
                setCountyrCodefromModal();
                setcountryCodeErr();
                setSlecetedClientDetailsToGetCountryCode();
                setflag(false)
              }
              else{
                Swal.fire({
                  icon: 'error',
                  text: clientEdited.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                setcountryCodeErr();
              }
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });

        }
      })
    }
  }

  const body = (
    <div className={classes.paperModal}>
      {console.log("in body ")}
      {/* <div className={classes.addNewBusinessHeaderBackgroud}>
        
      </div> */}
      <div className={classes.modalBody}>
        <div>
          <Grid style={{marginTop:'10%', fontSize:15}}>
            Plase select Country to add Country Code for phone number
          </Grid>
          <Grid style={{marginTop:'5%'}}>
            {/* <PhoneInput
              inputStyle={{
                width: "100%",
                height: "50px",
                fontSize: "15px",
              }}
              countryCodeEditable={false}
              onlyCountries={onlyCountryArray}
              // masks={{
              //   in: '..........', us: '..........', gb: '..........', ca: '..........', ae: '............',
              //   sg: '........', np: '............', jp: '..........', de: '............', bt: '............',
              //   au: '.............'
              // }}
              country={'in'}
              specialLabel="Phone Number*"
              // disabled
              
              // value={ClientDetailsEdit.phone}
              // onChange={(usersNumber, e, formattedValue, value) => phoneNumberChange(usersNumber, e, formattedValue, value)}
            /> */}

            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                native
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={CountyrCodefromModal}
                // defaultValue={superCategoryFromRedux}
                onChange={handleChangeCountyrCodefromModal}
              >
                <option value="" style={{ color: 'grey' }}>Select Country</option>
                {CountryListForCountryCode && CountryListForCountryCode.map(item =>
                  <option value={item.country_code}>{item.country}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(CountyrCodefromModal)?(<div></div>):(countryCodeErr)}</div>
          </Grid>
        </div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'10%'}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleSubmitMobileCountryCode}
          >
            Submit
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handleCloseMobileCountryCode}
          >
            Cancel
          </Button>
        </div>
      </div>

    </div>
  )

  if(RedirectToGetClientDetailsPage == true){
    return <Redirect to={`/TaxManagementClientDetailsUI`} />
  }
  if( RedirectToEditClientDetails == true ){
    return <Redirect to={`/EditClientSelf`} />
  }
  else{
  return (
    console.log("clientDetails in return",ClientDetails),
    console.log("paginationDetails in return",paginationDetails),
    <div className={classes.root}>
      <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40' loading='true' />
          </modal>: null}
      </div>
      <div className={classes.wrapper}>
        
        <Slide
          direction="right"
          in={!checked}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={
              !checked ? classes.casesdisplay : classes.casesdisplayNone
            }
          >
            <Grid container>
            
              <Grid item xs={6} className={classes.pageHeaderLabel}>
                {/* <FormattedMessage id="clientsLink" /> */}
                Client Details
              </Grid>
              <Grid item xs={2}>
                {/* <Button
                  variant="outlined"
                  className={classes.customFilterLabel}
                  startIcon={<FilterListIcon />}
                  onClick={handleDrawerOpen}
                >
                  <FormattedMessage id="filtersLabel" />
                </Button> */}
                <TextField 
                  id="outlined-basic"  
                  variant="outlined"
                  className={classes.SearchBoxStyle}
                  placeholder="Search"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon style={{color:'grey'}} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => getFilteredListFunc(e) }
                />             
              </Grid>

              {user_permissions && user_permissions.permissions.create_client &&
                // <Grid item xs={2}>
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonStyle}
                      startIcon={<AddIcon />}
                      href="/newclient"
                    >
                      <FormattedMessage id="newClientLabel" />
                    </Button>
                  </div>
                // </Grid>
              }

              {/* <Grid item xs={2}> */}
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonStyle}
                    // startIcon={<AddIcon />}
                    href="/clientBulkDashboard"
                  >
                    <FormattedMessage id="bulkIportLale" />
                  </Button>
                </div>
              {/* </Grid> */}

              
            </Grid>
            <Grid container style={{marginTop:'2%'}}>
              {/* loading  code */}
              {/* <Grid item xs={12}>
                {loading && clientList !== undefined && (
                  <TaxManagementClientTable handleChange={handleChange} 
                  clientList={clientList}
                  />
                )}
                {(!loading || clientList === undefined) && (
                <div className={classes.marginTopStyle}>
                <CircularProgress size={50} />
                </div>
                )}
              </Grid> */}
              {
                (ShowTableFlag === true) ?
                (
                  <Grid>
                    <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      // rowCount={ClientDetails && ClientDetails.length}
                      rowCount={paginationDetails && paginationDetails.total_clients}
                    />

                    <TableBody>
                        {stableSort(ClientDetails && ClientDetails, getComparator(order, orderBy))
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item, index) => {
                          // console.log("row in table map",item)
                            return (
                            <TableRow 
                                key={index}
                                hover={true} classes={{hover: classes.hover}}
                                // onRowClick={(event, rowData) => console.log("rowData",rowData)}
                                // hover
                                tabIndex={-1}
                                align="center"
                            >
                                <TableCell onClick={ () => handleRowClickOfTable(item)} width="5%" align="center" >{item.client_pan}</TableCell>
                                <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center" >{item.client_type}</TableCell>
                                <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center" >
                                  {/* {item.first_name} */}
                                  {
                                    (item.client_type == "person")?
                                    (
                                      <Grid>{item.first_name + ' '+ item.middle_name + ' ' + item.surname}</Grid>
                                    ):
                                    (
                                      <Grid>{item.org_name}</Grid>
                                    )
                                  }
                                </TableCell>
                                <TableCell onClick={ () => handleRowClickOfTable(item)} width="15%" align="center" >{item.country_code+-+item.phone}</TableCell>  
                                <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center" >{item.email}</TableCell>                                                   
                                <TableCell onClick={ () => handleRowClickOfTable(item)} width="12%" align="center" >{item.client_state}</TableCell>  
                                {
                                  ( item.client_state == "CREATED")?
                                  (
                                    <TableCell width="13%" align="center" >
                                      <Grid container >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          color="primary"
                                          className={classes.button}
                                          disabled={( user_permissions && user_permissions.permissions.create_client !== true ? true : null  )}
                                          onClick={() => handleClickInvited(item)}
                                        >
                                          Edit
                                        </Button>
                                        {/* &nbsp;&nbsp; */}
                                        {user_permissions && user_permissions.permissions.send_invites &&
                                          <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            className={(classes.button, classes.containedPurple)}
                                            onClick={() => handleClickInvite(item)}
                                          >
                                            Invite
                                          </Button>
                                        }
                                      </Grid>
                                      <Grid>
                                        <Button
                                          size="small"
                                          variant="contained"
                                          className={(classes.button, classes.containedPurple)}
                                          onClick={() => handleClickDetailsOfUnregistered(item)}
                                        >
                                          Details
                                        </Button>
                                      </Grid>
                                    </TableCell>)
                                  :(
                                    (item.client_state == "REGISTERED")?
                                    (
                                      <TableCell width="5%" align="center" >
                                        {user_permissions && user_permissions.permissions.view_client_details &&
                                          <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button} 
                                            disabled= {(user_permissions && user_permissions.permissions.view_client_details !== true ? true : null)}
                                            onClick={() => handleClickRegistered(item)}
                                          >
                                            Details
                                          </Button>
                                        }
                                      </TableCell>
                                    )
                                    :(
                                      (item.client_state == "INVITED")?
                                      (
                                        <TableCell width="12%" align="center">
                                          <Grid container >
                                              <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                disabled={( user_permissions && user_permissions.permissions.create_client !== true ? true : null  )}
                                                onClick={() => handleClickInvited(item)}
                                              >
                                                Edit
                                              </Button>
                                            
                                            {/* &nbsp;&nbsp; */}
                                            
                                              {user_permissions && user_permissions.permissions.send_invites &&
                                                <Button
                                                  size="small"
                                                  variant="contained"
                                                  // color="red"
                                                  className={(classes.button, classes.containedPurple)}
                                                  onClick={() => handleClickInvite(item)}
                                                >
                                                  Invite
                                                </Button>
                                              }


                                          </Grid>
                                          <Grid>
                                            <Button
                                              size="small"
                                              variant="contained"
                                              className={(classes.button, classes.containedPurple)}
                                              onClick={() => handleClickDetailsOfUnregistered(item)}
                                            >
                                              Details
                                            </Button>
                                          </Grid>
                                        </TableCell>
                                      ):
                                      (<TableCell width="5%" align="center" ></TableCell>)
                                    )
                                  )
                                }
                                                                                       
                            </TableRow>
                            );
                        })}
                            {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: ClientDetails && ClientDetails.length }]}
                                rowsPerPageOptions={[100, 50, 25, 10, { label: 'All', value: paginationDetails.total_clients }]}
                                colSpan={8}
                                // count={ClientDetails && ClientDetails.length}
                                count={paginationDetails && paginationDetails.total_clients}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
                  </Grid>
                ):
                (<></>)
              }
              
            </Grid>
          </div>
        </Slide>
        <div
          className={checked ? classes.casesdisplay : classes.casesdisplayNone}
        >
          <Slide
            direction="left"
            in={checked}
            timeout={500}
            mountOnEnter
            unmountOnExit
          >
            <div className={classes.additionalDetail}>
              <TaxManagementClientDetails handleChange={handleChange} />
            </div>
          </Slide>
        </div>
        <Drawer
          anchor={"right"}
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <FilterViewClient handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </div>
        <Modal
          open={OpenMobileCountryCode}
          onClose={handleCloseMobileCountryCode}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
    </div>
  );
  }
}
